import * as bootstrap from "bootstrap";
import slick from "slick-carousel";

// Include jquery
global.$ = global.jQuery = require("jquery");

$(function () {
  $("#video-container").slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  });

  // Slider
  $("#slider-popular").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    dots: true,
  });
});

// Navbar slider
$(".categories-nav").on("scroll", function (e) {
  var scrollWidth;
  (scrollWidth =
    $(".categories-nav")[0].scrollWidth - $(".categories-nav")[0].clientWidth),
    0 === $(".categories-nav").scrollLeft()
      ? $("#navbarBtnLeft").css({ display: "none" })
      : $(".categories-nav").scrollLeft() < scrollWidth
      ? $("#navbarBtnLeft, #navbarBtnRight").css({ display: "initial" })
      : $("#navbarBtnRight").css({ display: "none" });
});

$("#navbarBtnLeft").on("click", function (t) {
  $(".categories-nav").animate(
    {
      scrollLeft: "-=" + 150,
    },
    300
  );
});

$("#navbarBtnRight").on("click", function (t) {
  $(".categories-nav").animate(
    {
      scrollLeft: "+=" + 150,
    },
    300
  );
});

// Responsive
$(window).on("resize", (e) => {
  const screenSize = $(window).width();

  if (screenSize > 728) {
    $("#navbarBtnLeft").addClass("d-none");
    $("#navbarBtnRight").addClass("d-none");

    $("#mobileNav").addClass("d-none");
    $("#floresMobileNav").addClass("d-none");
  }
});

// Mobile nav
$("#flores").on("click", function (e) {
  const screenSize = $(window).width();

  if (screenSize > 728) {
    return "";
  }

  if ($("#mobileNav").hasClass("d-none")) {
    $("#mobileNav").removeClass("d-none");
    $("#timorMobileNav").addClass("d-none");
    $("#floresMobileNav").removeClass("d-none");
  } else {
    $("#mobileNav").addClass("d-none");
    $("#timorMobileNav").addClass("d-none");
    $("#floresMobileNav").addClass("d-none");
  }
});

$("#timor").on("click", function (e) {
  const screenSize = $(window).width();

  if (screenSize > 728) {
    return "";
  }

  if ($("#mobileNav").hasClass("d-none")) {
    $("#mobileNav").removeClass("d-none");
    $("#timorMobileNav").removeClass("d-none");
    $("#floresMobileNav").addClass("d-none");
  } else {
    $("#mobileNav").addClass("d-none");
    $("#timorMobileNav").addClass("d-none");
    $("#floresMobileNav").addClass("d-none");
  }
});
